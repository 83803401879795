<template>
  <b-form
    class="p-2"
    @submit.prevent="handleSubmit(onSubmit)"
    @reset.prevent="resetForm"
  >
    <!-- multiadi -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="multiadi"
    >
      <b-form-group :label="$t('groupName')" label-for="multiadi">
        <b-form-input
          v-model="blankRationData.multiadi"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
      >
        {{ !isEdit ? $t("add") : $t("edit") }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="outline-secondary"
        @click="hide"
      >
        {{ $t("cancel") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    formValidation: {
      type: Function,
      required: true,
    },
    ration: {
      type: Object,
      required: false,
      default: () => null,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      oneToFive: Array.from(Array(5), (_, i) => i + 1).map((val) => {
        return { label: val, value: val };
      }),
      oneToHundred: Array.from(Array(100), (_, i) => i + 1).map((val) => {
        return { label: `${val} / ${100 - val}`, value: val };
      }),
      blankRationData: {
        plantid: this.$store.state.app.selectedPlantId,
        multiadi: "",
      },
      resetblankration: {
        plantid: this.$store.state.app.selectedPlantId,
        multiadi: "",
      },
      templates: [],
    };
  },
  async mounted() {
    if (this.ration)
      this.blankRationData = {
        multiadi: this.ration.Multiadi,
        ...this.ration,
      };
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.blankRationData);
    },
  },
};
</script>
