<template>
  <div>
    <formula-list-add-new
      :is-add-new-formula-sidebar-active.sync="isAddNewFormulaSidebarActive"
      @refetch-data="refetchData"
    />
    <formula-list-edit
      :key="selectedFormula.id"
      :is-edit-formula-sidebar-active.sync="isEditFormulaSidebarActive"
      :formula.sync="selectedFormula"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
              <b-button
                v-if="companyCredits[0]"
                variant="primary"
                :disabled="companyCredits[0].leftformula < 1"
                @click="
                  isAddNewFormulaSidebarActive = !isAddNewFormulaSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("addFormula") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refFormulaListTable"
        class="position-relative"
        :items="fetchFormulas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Formula -->
        <template #cell(formulaname)="data">
          <b-link
            :to="{
              name: 'multiFormulaView',
              params: {
                formulaid: data.item.id,
                id: $store.state.app.selectedPlantId,
                tur: 'multi',
              },
            }"
          >
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.formulaname }}
            </span>
          </b-link>
        </template>
        <template #cell(preview)="data">
          <b-link
            :to="{
              name: 'multiFormulaView',
              params: {
                formulaid: data.item.id,
                id: $store.state.app.selectedPlantId,
                tur: 'multi',
              },
            }"
          >
            <feather-icon icon="EyeIcon" />
            <small class="align-middle ml-50 text-dark">{{ $t("view") }}</small>
          </b-link>
        </template>
        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
        <template #cell(delete)="data">
          <b-link
            class="text-danger cursor-pointer"
            @click="
              deleteFormula({
                id: data.item.id,
                plantid: $store.state.app.selectedPlantId,
                tur: 'multi',
              })
            "
          >
            <feather-icon class="text-danger" icon="Trash2Icon" />
            <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
              $t("delete")
            }}</small>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFormulas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useFormulaList from "./useFormulaList";
import FormulaListAddNew from "./FormulaListAddNew.vue";
import FormulaListEdit from "./FormulaListEdit.vue";
import VueI18n from "@/libs/i18n";
import store from "@/store";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    FormulaListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    FormulaListEdit,
    // vSelect,
  },

  setup({ emit }) {
    const isAddNewFormulaSidebarActive = ref(false);
    const isEditFormulaSidebarActive = ref(false);
    const selectedFormula = ref({});
    const openEditSideBar = ({ item }) => {
      selectedFormula.value = item;
      isEditFormulaSidebarActive.value = !isEditFormulaSidebarActive.value;
    };

    const companyCredits = computed(() => store.state.app.companyCredits);
    onMounted(() => {
      if (companyCredits.value == 0) {
        store.dispatch("app/fetchCompanyCredits", getUserData().companyid);
      }
    });

    const {
      fetchFormulas,
      tableColumns,
      perPage,
      currentPage,
      totalFormulas,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormulaListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteFormula,
    } = useFormulaList();

    return {
      // Sidebar
      isAddNewFormulaSidebarActive,
      isEditFormulaSidebarActive,
      openEditSideBar,

      selectedFormula,
      fetchFormulas,
      tableColumns,
      perPage,
      currentPage,
      totalFormulas,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFormulaListTable,
      refetchData,
      perPageOptions,
      companyCredits,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteFormula,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
